<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
    </template>
    <portal to="v-main"> </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
      @click="onClickRow($event)"
    />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      idEdit: 0,
      title: "",
      model: this.$store.getters["config/get"].models.rooms.list,
      url: "/kazan/rooms",
      defaults: {
        sort: { key: "id", order: "DESC" },
      },
    };
  },
  created() {
    this.$root.title = "Студии";
  },
  watch: {},
  methods: {
    onClickRow(d) {
      this.$router.push({ name: "rooms_view", params: { id: d.row.id } });
    },
  },
};
</script>
